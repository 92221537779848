<template>
  <div class="task" id="task">
    <van-sticky>
        <ListSearch :listConfig="listConfig" @search="search" style="position: fixed; z-index: 1000; width: 100%;" />
    </van-sticky>
	<HYList ref="HYList" v-bind="listConfig" style="position: relative; top: 113px;" >
		<template #columnHead="{ row }">
			<div>
                <div class="userInfo">
                    <div class="userInfoCon">
                        <p>
                            {{ row.plateNo }}
                        </p>
                        <p style="margin-top: 0.05rem;">
                            {{ dayjs(row.createTime).format('YYYY-MM-DD HH:mm:ss') }}
                        </p>
                    </div>
                    <span style="margin-right: 0.35rem; position: relative; bottom: 0.12rem;" v-if="row.existSmsRepairFlag === '1' || row.existRenewalFlag === '1'">
                        <van-image
                            width="40"
                            height="40"
                            :src="require('@/assets/images/weixiu.svg')"
                            :class="{ 'isOpacity': row.existSmsRepairFlag === '0' || !currentMenus.includes('/mpRepairSmsList') }"
                            @click="toShortDetail(row.repairSmsId, row.existSmsRepairFlag, !currentMenus.includes('/mpRepairSmsList'))"
                            style="margin-right: 0.1rem;"
                        />
                        <van-image
                            width="40"
                            height="40"
                            :src="require('@/assets/images/xubao.svg')"
                            @click="toTaskDetail(row.renewalTaskId, row.existRenewalFlag, !currentMenus.includes('/mpRenewalTask'))"
                            :class="{ 'isOpacity': row.existRenewalFlag === '0' || !currentMenus.includes('/mpRenewalTask') }"
                        />
                    </span>
                    <span class="empty" v-else>
                        无
                    </span>
                </div>
			</div>
		</template>
		<template #columnFoot="{}">
		</template>
		<template #empty>
			<div class="none-tip">
				<van-image
				:src="require('@/assets/images/none_data.jpg')"
				/>
				<p>尊敬的用户，您当前暂无进场信息</p>
			</div>
		</template>
	</HYList>
  </div>
</template>
<script>
import HYList from '@/components/List/index'
import dayjs from 'dayjs'
import { mapState } from "vuex";
import ListSearch from './components/listSearch'
import store from '@/store'
export default {
	name:'Approach',
	components: { HYList, ListSearch },
	provide () {
		return {
			pageContext: this.pageContext
		}
	},
	data() {
		return {
            listConfig:{
				api:{
					url:'/afis-renewal/repair/arriveVehicleRecord/list'
				},
				form:{
					plateNo:'',
                    queryFlag: '1'
				},
				column:[],
                styleObj: {}
			}
		}
	},
	computed: {
        ...mapState("dict", ["dictMap"]),
        ...mapState("user", ["currentMenus"])
    },
	async created(){
        store.dispatch('user/load')
	},
	mounted () {
        task.style.height = (document.documentElement.clientHeight - 60) + 'px'
    },
	methods:{
		dayjs,
        search () {
            this.$refs.HYList.onRefresh()
        },
        toTaskDetail (id, type) {
            if (type === '0') return
            this.$router.push({
                path: '/follow',
                query: {
                    id: id,
                    fromRoute: '/mpArriveVehicleRecord'
                }
            })
        },
        toShortDetail (id, type, flag) {
            if (type === '0' || flag) return
            this.$router.push({
                path: '/shortDetail',
                query: {
                    id: id
                }
            })
        }
	}
}
</script>
<style lang="scss" scoped>
.task {
    overflow: auto;
    p {
        margin: 0;
        padding: 0;
    }
    .none-tip {
        position: relative;
        top: 20%;
        text-align: center;
        p {
            margin-top: 10px;
            text-align: center;
        }
    }
    .isOpacity {
        opacity: 0.4;
    }
    .empty {
        width: 40px;
        height: 40px;
        font-size: 16px;
        display: inline-block;
        background: rgb(0, 153, 255);
        margin-right: 0.4rem;
        color: #fff;
        border-radius: 50%;
        text-align: center;
        line-height: 40px;
    }

    .carInfo {
        height: 0.8rem;
        line-height: 0.8rem;
        padding-left: 0.245rem;
        p {
            margin: 0;
            padding: 0;
        }
        .plateNo {
            font-size: 0.3rem;
            position: inherit;
            .surplus {
                color: #37B8AD;
                font-size: 0.24rem;
                display: inline-block;
                width: 80px;
                border: 1px solid #37B8AD;
                height: 0.4rem;
                line-height: 0.4rem;
                text-align: center;
                border-radius: 0.04rem;
            }
            .phone-img {
                height: 0.5rem; width: 0.5rem; position: absolute; right: 0.31rem; top: 0.15rem;
            }
        }
    }
    .line {
        border-bottom: 1px solid rgba(151,151,151, .3);
    }
    .userInfo {
        height: 1rem;
        padding-left: 0.245rem;
        margin-top: 0.175rem;
        width: 100%;
        display: flex;
        justify-content: space-between;
        padding-top: 0.1rem;
        .userInfoCon {
            height: 0.37rem;
            line-height: 0.37rem;
            font-size: 0.26rem;
        }
        .title {
            color: #B6B9BF;
            font-size: 0.26rem;
            display: inline-block;
            width: 1.3rem;
            text-align: left;
        }
        .titledes {
            font-size: 0.26rem;
            color: #1B1B1B;
        }
        .status-img {
            width: 1.2rem;
            height: 1.2rem;
            width: 1.2rem;
            height: 1.2rem;
            position: absolute;
            right: 0.84rem;
            bottom: 0.27rem;
        }
    }
}
</style>